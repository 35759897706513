import axios from "axios";
import {
  POST_CREATE_SUCCESS,
  POST_CREATE_FAIL,
  POST_GET_SUCCESS,
  POST_GET_FAIL,
  POST_UPDATE_FAIL,
  POST_UPDATE_SUCCESS,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,
  ERROR_CLEAR,
  SUCCESS_MESSAGE_CLEAR,
  POSTMETA_CREATE_SUCCESS,
  POSTMETA_CREATE_FAIL,
  POSTMETA_GET_SUCCESS,
  POSTMETA_GET_FAIL,
  POSTMETA_UPDATE_SUCCESS,
  POSTMETA_UPDATE_FAIL,
  POSTMETA_DELETE_SUCCESS,
  POSTMETA_DELETE_FAIL
} from "../types/postTypes";

import { SERVER_URL } from '../../config/dev';
import { current } from "@reduxjs/toolkit";

// import { jwtDecode } from 'jwt-decode'

const initialState = {
  posts: null,
  current: null,
  postmeta: [],
  allPostMeta: [],
  successMessage: {
    create: "",
    get: "",
    update: "",
    delete: ""
  },
  errorMessage: null
};

export const PostReducer = (state = initialState, action) => {
  const { payload, type } = action;

  if (type === POST_CREATE_SUCCESS) {
    return {
      ...state,
      successMessage: {
        ...state.successMessage,
        create: payload.successMessage,
      },
      posts: payload.posts,
      current: payload.current
    }
  }

  if (type === POST_CREATE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === POST_GET_SUCCESS) {
    return {
      ...state,
      posts: payload.posts,
      successMessage: {
        ...state.successMessage,
        get: payload.successMessage,
      },
    }
  }

  if (type === POST_GET_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === POST_UPDATE_SUCCESS) {
    return {
      ...state,
      posts: payload.posts,
      current: payload.current,
      successMessage: {
        ...state.successMessage,
        update: payload.successMessage,
      },
    }
  }

  if (type === POST_UPDATE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === POST_DELETE_SUCCESS) {
    return {
      ...state,
      posts: payload.posts,
      successMessage: {
        ...state.successMessage,
        delete: payload.successMessage,
      },
    }
  }

  if (type === POST_DELETE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }




  if (type === POSTMETA_CREATE_SUCCESS) {
    return {
      ...state,
      successMessage: {
        ...state.successMessage,
        create: payload.successMessage,
        current: null,
      },
      postmeta: payload.postmeta
    }
  }

  if (type === POSTMETA_CREATE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
      current: null,
    };
  }

  if (type === POSTMETA_GET_SUCCESS) {
    return {
      ...state,
      postmeta: payload.postmeta,
      allPostMeta: payload.allPostMeta,
      successMessage: {
        ...state.successMessage,
        get: payload.successMessage,
      },
    }
  }

  if (type === POSTMETA_GET_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === POSTMETA_UPDATE_SUCCESS) {
    return {
      ...state,
      postmeta: payload.postmeta,
      successMessage: {
        ...state.successMessage,
        update: payload.successMessage,
      },
    }
  }

  if (type === POSTMETA_UPDATE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === POSTMETA_DELETE_SUCCESS) {
    return {
      ...state,
      postmeta: payload.postmeta,
      successMessage: {
        ...state.successMessage,
        delete: payload.successMessage,
      },
    }
  }

  if (type === POSTMETA_DELETE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === SUCCESS_MESSAGE_CLEAR) {
    return {
      ...state,
      successMessage: {
        create: "",
        get: "",
        update: "",
        delete: ""
      },
    }
  }

  if (type === ERROR_CLEAR) {
    return {
      ...state,
      errorMessage: null
    }
  }

  return state;
}